*{
  user-select: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


.main-container{
  background: url("https://m.media-amazon.com/images/I/81gC7frRJyL._SX679_.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
}

th {
  padding: 10px 20px;
}

td {
  padding: 10px 20px;
}

/* loader.css */

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  height: 40px;
  position: relative;
  --c:no-repeat linear-gradient(#ff9100 0 0);
  background:
    var(--c) center/100% 10px,
    var(--c) center/10px 100%;
}
.loader:before {
  content:'';
  position: absolute;
  inset: 0;
  background:
    var(--c) 0    0,
    var(--c) 100% 0,
    var(--c) 0    100%,
    var(--c) 100% 100%;
  background-size: 15.5px 15.5px;
  animation: l16 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l16 {
   33%  {inset:-10px;transform: rotate(0deg)}
   66%  {inset:-10px;transform: rotate(90deg)}
   100% {inset:0    ;transform: rotate(90deg)}
}


::-webkit-scrollbar {
  display: none;
  /* width: 1px; */
}
::-webkit-scrollbar-thumb {
  background: #454547;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8c92ac;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}





.FilterLoader {
  width: 90px;
  height: 30px;
  box-shadow: 0 3px 0 #fff;
  --g:repeat-x repeating-linear-gradient(90deg,#ff6a0070 0 16px,#0000 0 18px,#ff6a0070 0 34px,#0000 0 100%);
  background: var(--g),var(--g);
  background-size: 60px 14px,60px 14px;
  animation: l8 1s infinite linear;
}
@keyframes l8 {
  0%   {background-position:0    0,0    100%}
  100% {background-position:60px 0,60px 100%}
}


/* Not found */


/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  /* font-family: "Arvo", serif; */
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
background-repeat: no-repeat;
/* background-size: cover; */
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
